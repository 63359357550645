import styled from "styled-components"
import React from "react"

const PriceBox = ({ title, price, children }) => {
    return (
        <PricingBox>
            <div className="pricing-box-title">
            <PricingBoxTitle>{title}</PricingBoxTitle>
            <p>już od</p>
            </div>
            <PricingBoxPrice>
                <h2 className="size-62"><span className="size-28"></span>{price}<span className="size-28"> zł</span></h2>
                <p className="uppercase">{children}</p>
            </PricingBoxPrice>
      </PricingBox>
    )
}

const PricingBox = styled.div`
    text-align: center;
    padding: 30px 0;
    border: 1px solid #dddddd;
    margin-bottom: 30px;
    
`

const PricingBoxTitle = styled.h6`
    min-height: 66px;
    text-transform: uppercase;
    letter-spacing: 2px;
     padding-left: 15px;
     padding-right: 15px;
    line-height: 30px;

`

const PricingBoxPrice = styled.div`
    background: #20232a;
    padding-top: 20px;
    padding-bottom: 5px;
    color: #ffffff;
    border-top: 3px solid #eeb313;
`

export default PriceBox
