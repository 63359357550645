import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import PriceBox from "../components/priceBox"

const Cennik = () => (
	<Layout pageInfo={{ pageName: "cennik" }}>
		<SEO title="Cennik" description="Sprawdź orientacyjne ceny usług dekarskich. Każdą wycenę przygotowujemy pod projekt. Skontaktuj się z nami." />
		<Container fluid className="info-bar p-4">
			<div>
				<b>SKONTAKTUJ SIĘ Z NAMI - PRZYGOTUJEMY WYCENĘ TWOJEGO DACHU</b>
			</div>
		</Container>
		<Container>
			<div className="divider">Ceny usług</div>
			<p className="subtitle par-grey fw-400 fs-125 mb-100">Podane ceny są wartościami przybliżonymi, skontaktuj się z nami w celu indywidualnej wyceny</p>
			<Row className="justify-content-center">
				<Col md={6} lg={4}>
					<PriceBox title="Ułożenie dachówki" price="60">
						za m<sup>2</sup>
					</PriceBox>
				</Col>
				<Col md={6} lg={4}>
					<PriceBox title="Ułożenie blachodachówki" price="40">
						za m<sup>2</sup>
					</PriceBox>
				</Col>
				<Col md={6} lg={4}>
					<PriceBox title="Ułożenie papy termozgrzewalnej" price="15">
						za mb
            </PriceBox>
				</Col>
				<Col md={6} lg={4}>
					<PriceBox title="Ocieplenie dachu styropianem" price="15">
						za m<sup>2</sup>
					</PriceBox>
				</Col>
				<Col md={6} lg={4}>
					<PriceBox title="Montaż rynny lub rury spustowej" price="25">
						za mb
          			</PriceBox>
				</Col>
				<Col md={6} lg={4}>
					<PriceBox title="Wykonanie obróbek blacharskich" price="40">
						za m<sup>2</sup> blachy
					</PriceBox>
				</Col>
			</Row>
		</Container>
	</Layout>
)

export default Cennik
